<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="dataForm.orgId"
            :disabled="disabled || isValid"
            @change="getPeriodList()"
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select
            v-model="dataForm.companyId"
            :disabled="disabled || isValid"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="生效日期" prop="startDate">
          <el-date-picker
            v-model="dataForm.startDate"
            :disabled="disabled"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="终止日期" prop="endDate">
          <el-date-picker
            v-model="dataForm.endDate"
            :disabled="disabled"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="餐标" prop="mealLabel">
          <el-input
            v-model="dataForm.mealLabel"
            :disabled="disabled"
            placeholder="请输入餐标"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="dataForm.remarks"
            :disabled="disabled"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="时段" prop="orgPeriodIds">
          <el-select
            v-model="dataForm.orgPeriodIds"
            :disabled="disabled || isValid"
            filterable
            clearable
            multiple
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.periodWithTimeName"
              :value="item.orgPeriodId"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList, getCompanyList } from '@/utils/options.js';
export default {
  data() {
    return {
      disabled: false,
      isValid: false,
      visible: false,
      dataForm: {
        id: '',
        orgId: '',
        companyId: '',
        mealLabel: '',
        remarks: '',
        orgPeriodIds: [],
        startDate: '',
        endDate: '',
      },
      orgList: [],
      companyList: [],
      periodList: [],
      dataListSelections: [],
      dataRule: {
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        orgPeriodIds: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
        startDate: [
          { required: true, message: '生效日期不能为空', trigger: 'blur' },
        ],
        endDate: [
          { required: true, message: '终止日期不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getOrgList();
    this.getCompanyList();
  },
  methods: {
    init(id, disabled, isValid) {
      this.dataForm.orgPeriodIds = [];
      this.periodList = [];
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.isValid = isValid;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgCompanyContract/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.orgCompanyContract };
              this.dataForm.orgPeriodIds = data.orgCompanyContract.orgPeriodIds
                ? data.orgCompanyContract.orgPeriodIds
                    .split(',')
                    .map((item) => parseInt(item))
                : [];
              this.getPeriodList();
            }
          });
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    //
    selectionChange(val) {
      this.dataListSelections = val;
    },
    getPeriodList() {
      if (this.dataForm.orgId) {
        this.$http({
          url: `/tc/orgperiod/combo/${this.dataForm.orgId}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgCompanyContract/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id,
              orgId: this.dataForm.orgId,
              companyId: this.dataForm.companyId,
              startDate: this.dataForm.startDate,
              endDate: this.dataForm.endDate,
              mealLabel: this.dataForm.mealLabel,
              remarks: this.dataForm.remarks,
              orgPeriodIds: this.dataForm.orgPeriodIds.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inputNumberInside {
  width: 100%;
  .el-input__inner {
    border: none;
  }
}
.tableLine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  .leftLine {
    font-size: 12px;
    width: 160px;
    padding: 0 10px 0 0;
    text-align: left;
  }
  .rightLine {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .longTime {
      width: 100%;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
    .leftname {
      width: 90px;
      text-align: center;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
    .halfTime {
      width: auto;
      flex: 1;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}

.newTablePart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  .newTable {
    width: 70%;
    position: relative;
  }
  .firstTable {
    width: 30%;
    position: relative;
  }
}
</style>
