<template>
  <el-dialog
    title="合同有效期记录"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <div class="block">
      <div class="radio">
        排序：
        <el-radio-group v-model="reverse">
          <el-radio :label="true">倒序</el-radio>
          <el-radio :label="false">正序</el-radio>
        </el-radio-group>
      </div>

      <el-timeline :reverse="reverse">
        <el-timeline-item
          v-for="(contract, index) in dataList"
          :key="index"
          :timestamp="contract.createdAt"
        >
          <el-card>
            <h4>生效日期:{{ contract.startDate }}</h4>
            <h4>终止日期:{{ contract.endDate }}</h4>
            <p>修改者:{{ contract.createdBy }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      reverse: true,
      dataForm: {},
      dataList: [],
      dataRule: {},
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      this.reverse = true;
      this.$nextTick(() => {
        if (id) {
          this.$http({
            url: `/tc/orgCompanyContractLog/queryAll`,
            params: {
              orgCompanyContractId: id,
            },
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataList = data.list;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss"></style>
